import axios from 'axios'
import authConfig from '../configs/auth'

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "authorization": `Bearer ${window.localStorage.getItem(authConfig.storageTokenKeyName)}`,
  },
});

// You can set default headers like Content-Type here
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor to set the authorization header
axiosInstance.interceptors.request.use((config) => {
  // Get the token from localStorage and set the authorization header
  const token = window.localStorage.getItem(authConfig.storageTokenKeyName);
  if (token) {
    config.headers['authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// axiosInstance.interceptors.response.use(
//   (response) => {
//     // If the response is successful, return it
//     return response;
//   },
//   (error) => {
//     // If the response status is 401 (unauthorized), redirect to the login page
//     if (error.response.status === 401) {
//       // Redirect to the login page
//       window.location.href = '/login';
//     }
//     // Return the error
//     return Promise.reject(error);
//   }
// );