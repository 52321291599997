import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import { defaultACLObj } from './configs/acl'
import { useEffect } from 'react'

const LoginPage = React.lazy(() => import('./pages/login'))
const ForgotPassword = React.lazy(() => import('./pages/login/forgotpassword'))
const HomePage = React.lazy(() => import('./pages/home'))
/** Dashboards Routes */
const DashboardCRMPage = React.lazy(() => import('./pages/dashboards/crm'))
//patient-list 
const PatientList = React.lazy(() => import('./pages/apps/patient'))
const Patientviewdetail = React.lazy(() => import('./pages/apps/patient/patientviewdetail'))
//CaregiverList
const CaregiverList = React.lazy(() => import('./pages/apps/caregiver'))
const AddCaregiverPage = React.lazy(() => import('./pages/apps/caregiver/AddCaregiverPage'))
const Caregiverviewdetail = React.lazy(() => import('./pages/apps/caregiver/caregiverviewdetail'))
const AppointmentList = React.lazy(() => import('./pages/apps/appointment'))
const AppointmentViewDetail = React.lazy(() => import('./pages/apps/appointment/appointmentviewdetail'))
const PaymentHistoryList = React.lazy(() => import('./pages/apps/payment'))
const TodaysPaymentList = React.lazy(() => import('./pages/apps/payment/todaypayment/index'))
const SettingsPage = React.lazy(() => import('./pages/apps/settings'))
const ContentPage = React.lazy(() => import('./pages/apps/settings/content'))
const HolidayList = React.lazy(() => import('./pages/apps/holiday'))
const LanguagesList = React.lazy(() => import('./pages/apps/addlang'))
const ServicesList = React.lazy(() => import('./pages/apps/service'))
const ApkPage = React.lazy(() => import('./pages/apps/apk'))


/** Other routes */
const UserProfilePage = React.lazy(() => import('./pages/user-profile/profile'))

/** PAGES */

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

const APP_VERSION_LOCAL_STORAGE_KEY = "CAREDAC_APP_VERSION"
const APP_VERSION = "1.0.0"

function App() {
  const aclAbilities = defaultACLObj

  const checkAppVersion = () => {
    let currentAppVersion = localStorage.getItem(APP_VERSION_LOCAL_STORAGE_KEY);
    if (currentAppVersion !== APP_VERSION) {
      localStorage.clear();
      localStorage.setItem(APP_VERSION_LOCAL_STORAGE_KEY, APP_VERSION);
      window.location.reload();
    }
  }

  useEffect(() => {
    checkAppVersion();
  }, [])

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AuthGuard fallback={<FallbackSpinner />}>
        <AclGuard aclAbilities={aclAbilities}>
          <Routes>
            <Route
              element={
                <BlankLayout>
                  <Outlet />
                </BlankLayout>
              }
            >
              <Route path='/login' element={<LoginPage />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/401' element={<Page401 />} />
              <Route path='/404' element={<Page404 />} />
            </Route>

            <Route
              element={
                <BlankLayoutWithAppBar>
                  <Outlet />
                </BlankLayoutWithAppBar>
              }
            >
            </Route>

            <Route
              path='/'
              element={
                <UserLayout>
                  <Outlet />
                </UserLayout>
              }
            >
              <Route path='' element={<HomePage />} />
              <Route
                path='dashboards' element={<DashboardCRMPage />} />
              <Route path='/client' element={<PatientList />} />
              <Route path='/client/:patientId' element={<Patientviewdetail />} />
              <Route path='/caregiver' element={<CaregiverList />} />
              <Route path="/caregiver/add" element={<AddCaregiverPage />} />
              <Route path='/caregiver/:caregiverId' element={<Caregiverviewdetail />} />
              <Route path='/appointment' element={<AppointmentList />} />
              <Route path='/appointment/:appointmentId' element={<AppointmentViewDetail />} />
              <Route path='/payment-history' element={<PaymentHistoryList />} />
              <Route path='/todays-payment' element={<TodaysPaymentList />} />
              <Route path='/settings' element={<SettingsPage />} />
              <Route path='/holiday' element={<HolidayList />} />
              <Route path='/languages' element={<LanguagesList />} />
              <Route path='/content' element={<ContentPage />} />
              <Route path='/services' element={<ServicesList />} />
              <Route path='/apk-api-sharing' element={<ApkPage />} />
              {/* User Detail Page */}
              <Route path='user-profile/profile/' element={<UserProfilePage />} />
            </Route>

            <Route path='*' element={<Navigate to='/404' replace />} />
          </Routes>
        </AclGuard>
      </AuthGuard>
    </Suspense>
  )
}

export default App
