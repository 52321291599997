// Development
// export const API_BASE_URL = 'https://dev.caredac.com'

// Production
export const API_BASE_URL = 'https://dev.caredac.com'

// Development
// export const MEDIA_URL = "https://d28qzefaa2n2nh.cloudfront.net";

// Production
export const MEDIA_URL = "https://dlhgrltgo56b6.cloudfront.net";

export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/api/v1/admin/login`,
    me: `${API_BASE_URL}/api/v1/admin/profile`,
  },
  USERS_COUNTS: {
    getcount: `${API_BASE_URL}/api/v1/admin/dashboard`,
  },
  PATIENT_LIST: {
    getpatient: `${API_BASE_URL}/api/v1/admin/user`,
    getpatientbyid: (id) => `${API_BASE_URL}/api/v1/admin/user/${id}`,
    deletepatient: (id) => `${API_BASE_URL}/api/v1/admin/user/${id}`,
    patientappintment: (id) => `${API_BASE_URL}/api/v1/admin/appointment-list/${id}`
  },
  CAREGIVER_LIST: {
    addCaregiver: `${API_BASE_URL}/api/v1/admin/caregiver-registration`,
    editCaregiver: (id) => `${API_BASE_URL}/api/v1/admin/caregiver/${id}`,
    caregiverslot: (id) => `${API_BASE_URL}/api/v1/admin/availability/caregiver/${id}`,
    verifyDoc: (id) => `${API_BASE_URL}/api/v1/admin/verify-doc/caregiver/${id}`,
    unavailabilityslot: (id) => `${API_BASE_URL}/api/v1/admin/unavailability/caregiver/${id}`,
    languagespeak: `${API_BASE_URL}/api/v1/common/language-speak`,
    addlanguagespeak: `${API_BASE_URL}/api/v1/admin/language-speak`,
    deletelanguagespeak: (id) => `${API_BASE_URL}/api/v1/admin/language-speak/${id}`,
    getcaregiver: `${API_BASE_URL}/api/v1/admin/caregivers`,
    getcaregiverbyid: (id) => `${API_BASE_URL}/api/v1/admin/user/${id}`,
    changeStatus: (id) => `${API_BASE_URL}/api/v1/admin/account-status/user/${id}`,
    changeBackgroundStatus: (id) => `${API_BASE_URL}/api/v1/admin/background-status/caregiver/${id}`,
  },
  Appointment: {
    // getappointment: `${API_BASE_URL}/api/v1/admin/appointment`,
    getappointment: `${API_BASE_URL}/api/v1/admin/appointment`,
    getappointmentbyid: (id) => `${API_BASE_URL}/api/v1/admin/appointment/${id}`,
    editappointment: (id) => `${API_BASE_URL}/api/v1/admin/appointment/${id}`,
  },
  SERVICE_COST: {
    getservice: `${API_BASE_URL}/api/v1/admin/services-cost`,
    addservice: `${API_BASE_URL}/api/v1/admin/services-cost`,
    editservice: `${API_BASE_URL}/api/v1/admin/services-cost`
  },
  GUIDELINES: {
    getguidelines: `${API_BASE_URL}/api/v1/admin/guidelines`,
    editservice: `${API_BASE_URL}/api/v1/admin/guidelines`
  },
  POLICIES: {
    getpolicies: `${API_BASE_URL}/api/v1/admin/policies`,
    editpolicies: `${API_BASE_URL}/api/v1/admin/policies`
  },
  TERMS: {
    getterms: `${API_BASE_URL}/api/v1/admin/terms`,
    editterms: `${API_BASE_URL}/api/v1/admin/terms`
  },
  TRUST_SAFETY: {
    gettrust: `${API_BASE_URL}/api/v1/admin/trust-safety`,
    edittrust: `${API_BASE_URL}/api/v1/admin/trust-safety`
  },
  HOLIDAY: {
    getholidays: `${API_BASE_URL}/api/v1/admin/holiday`,
    addholidays: `${API_BASE_URL}/api/v1/admin/holiday`,
    deleteholidays: (id) => `${API_BASE_URL}/api/v1/admin/holiday/${id}`
  },
  PAYMENTHISTORY: {
    gethistory: `${API_BASE_URL}/api/v1/payment/payments`
  },
  SERVICE: {
    list: `${API_BASE_URL}/api/v1/common/services-list`,
    add: `${API_BASE_URL}/api/v1/admin/services`,
    edit: (id) => `${API_BASE_URL}/api/v1/admin/services/${id}`,
    delete: (id) => `${API_BASE_URL}/api/v1/admin/services/${id}`
  },
  APK_API: {
    list: `${API_BASE_URL}/api/v1/mobile-application`,
    add: `${API_BASE_URL}/api/v1/mobile-application`,
    delete: (id) => `${API_BASE_URL}/api/v1/mobile-application/${id}`
  }
}